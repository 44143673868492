import { createSlice } from '@reduxjs/toolkit'
import {PLAYER_TYPE} from "../../constants";
import {formatMiliseconds, formatTime, normalizeValue} from "../../views/Demo/helpers";
import _ from "lodash";
import {calculateFrequencyPerMinute, detectBlink} from "../../utils/bioFeedback";
import AttentionMetricsCalculator from "../../utils/bioFeedback/attentionMetricsCalculator";
import {BLINK_FREQUENCY_DURATION_SECONDS, BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS} from "../../api/remoteConfig";

const initialState = {
    overtime: [],
    overtimeById: {},
    widgetResultsArray: [],
    widgetResults: {},
    alertsData: {},
    overtimeHistory: [],
    alertsStats:{
        totalAttentionTime: 0,
        minAttentionTime: 0,
        maxAttentionTime: 0,
        avgAttentionTime: 0,
    }
}

const attentionCalculator = new AttentionMetricsCalculator();

export const createAlertTemplate = () => {
    return{
        totalYawnTime: 0,
        yawnCount: 0,
        yawnPerMinute: 0,
        leftEyeClosedTimeInARow: 0,
        rightEyeClosedTimeInARow: 0,
        blinkCount: 0,
        headFacingCamera: true,
        yawning: false, // mouth is open over X seconds
        highStress: false, // stress is over X
        eyesClosed: false, // eyes are closed over X seconds
        focusTime: 0

    }
}

function roundAndNormalize(value) {
    if(value === null || value === undefined){
        return value
    }
    return Math.round(normalizeValue(value) * 100);
}

export const emotionalDataSlice = createSlice({
    name: 'emotionalData',
    initialState,
    reducers: {
        pushToOvertime: (state, action) => {
            const {data, playerType, vidCurrTime, timeSpent} = action.payload

            let updatedOvertime = _.cloneDeep(state.overtime)
            let index;
            if ([PLAYER_TYPE.VIDEO_UPLOAD, PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType)) {
                index = formatTime(vidCurrTime)
            } else {
                index = formatMiliseconds(timeSpent);
            }

            let items =[]
             let item = {}
            let generalItem = {}

             for(let i = 0; i < data.length; i++) {
                 let d = data[i]

                 let yawnRatio = d.faceBlendshapes ? Math.round(d.faceBlendshapes.jawOpen * 100) : null;
                 let eyebrowEyeRatio = d.faceBlendshapes ? calculateEyebrowDirection(d.faceBlendshapes.browsOuterUp, d.faceBlendshapes.browsDown) : null ;
                 let frownScore = (d.frown ? d.frown.frownScore : null) - 200;
                 let pitchAngle = d.headPose ? d.headPose.pitch : null
                 let noseEyebrowRatio = d.frown ? d.frown.noseEyebrowRatio : null ;
                 let yawAngle = (d.headPose ? d.headPose.yaw : null);
                 let eyesBlink = d.faceBlendshapes ? d.faceBlendshapes.eyesBlink : null;
                 let lightIndex = d.lightIndex
                 let leftRightLightDifference = d.leftRightLightDifference;
                 let topBottomLightDifference = d.topBottomLightDifference;
                 let faceBackgroundLightDifference = d.faceBackgroundLightDifference;
                 let lux = d.lux;
                 let lux2 = d.lux2;
                 let filterReason = d.filterReason;


                 if (yawnRatio > 100) {
                     yawnRatio = 100
                 }

                 if (frownScore > 100) {
                     frownScore = 100
                 } else if (frownScore < 0) {
                     frownScore = 0
                 }

                 if(noseEyebrowRatio > 350){
                     noseEyebrowRatio = 350
                 }else if(noseEyebrowRatio < 300){
                     noseEyebrowRatio = 300
                 }


                 item = {
                     ...item,
                     index,
                     timeSpent: timeSpent,
                     id: d.id,
                     [`mood_${d.id}`] : roundAndNormalize(d.valence),
                     [`energy_${d.id}`]: roundAndNormalize(d.energy),
                     [`wellbeing_${d.id}`]: roundAndNormalize(d.wellbeing),
                     [`engagement_${d.id}`]: roundAndNormalize(d.engagement),
                     [`interest_${d.id}`]: roundAndNormalize(d.interest),
                     [`stress_${d.id}`]: roundAndNormalize(d.stress),
                     [`closedEyes_${d.id}`]: eyesBlink ? (eyesBlink) * 100 : null,
                     [`yawn_${d.id}`]: roundAndNormalize(d.yawn),
                     [`yawnRatio_${d.id}`]: yawnRatio,
                     [`mask_${d.id}`]: roundAndNormalize(d.mask),
                     [`noMask_${d.id}`]: roundAndNormalize(d.noMask),
                     [`closedLeftEye_${d.id}`]: roundAndNormalize(d.closedLeftEye),
                     [`openLeftEye_${d.id}`]: roundAndNormalize(d.openLeftEye),
                     [`closedRightEye_${d.id}`]: roundAndNormalize(d.closedRightEye),
                     [`openRightEye_${d.id}`]: roundAndNormalize(d.openRightEye),
                     [`openEyes_${d.id}`]:  eyesBlink ? (1- eyesBlink) * 100 : null,
                     [`yawAngle_${d.id}`]: yawAngle,
                     [`pitchAngle_${d.id}`]: pitchAngle,
                     [`eyebrowEyeRatio_${d.id}`]: eyebrowEyeRatio,
                     [`frownScore_${d.id}`]: frownScore,
                     [`noseEyebrowRatio_${d.id}`]: noseEyebrowRatio,
                     [`eyesBlink_${d.id}`]: eyesBlink,
                     [`fatigue_${d.id}`]: Math.round(d.fatigue),
                     [`attention_${d.id}`]: Math.round(d.attention),
                     [`lightIndex_${d.id}`]: lightIndex,
                     [`leftRightLightDifference_${d.id}`] : leftRightLightDifference,
                     [`topBottomLightDifference_${d.id}`] : topBottomLightDifference,
                     [`faceBackgroundLightDifference_${d.id}`] : faceBackgroundLightDifference,
                     [`lux_${d.id}`] : lux,
                     [`lux2_${d.id}`] : lux2,
                        [`filterReason_${d.id}`] : filterReason
                 }

                 generalItem ={
                     ...item,
                     index,
                     timeSpent: timeSpent,
                     id: d.id,
                     [`mood`] : roundAndNormalize(d.valence),
                     [`energy`]: roundAndNormalize(d.energy),
                     [`wellbeing`]: roundAndNormalize(d.wellbeing),
                     [`engagement`]: roundAndNormalize(d.engagement),
                     [`interes}`]: roundAndNormalize(d.interest),
                     [`stress`]: roundAndNormalize(d.stress),
                     [`closedEyes`]: eyesBlink ? (eyesBlink) * 100 : null,
                     [`yawn`]: roundAndNormalize(d.yawn),
                     [`yawnRatio`]: yawnRatio,
                     [`mask`]: roundAndNormalize(d.mask),
                     [`noMask`]: roundAndNormalize(d.noMask),
                     [`closedLeftEye`]: roundAndNormalize(d.closedLeftEye),
                     [`openLeftEye`]: roundAndNormalize(d.openLeftEye),
                     [`closedRightEye`]: roundAndNormalize(d.closedRightEye),
                     [`openRightEye`]: roundAndNormalize(d.openRightEye),
                     [`openEyes`]: eyesBlink ? (1- eyesBlink) * 100 : null,
                     [`yawAngle`]: yawAngle,
                     [`pitchAngle`]: pitchAngle,
                     [`eyebrowEyeRatio`]: eyebrowEyeRatio,
                     [`frownScore`]: frownScore,
                     [`noseEyebrowRatio`]: noseEyebrowRatio,
                     [`eyesBlink`]: eyesBlink,
                     [`fatigue`]: Math.round(d.fatigue),
                     [`attention`]: Math.round(d.attention),
                     [`lightIndex`]: lightIndex,
                     lux: lux,
                     lux2: lux2,
                     leftRightLightDifference: leftRightLightDifference,
                     topBottomLightDifference: topBottomLightDifference,
                     faceBackgroundLightDifference: faceBackgroundLightDifference,
                     frown: {
                         ...item.frown,
                         eyebrowEyeRatio,
                         frownScore,
                         noseEyebrowRatio,
                     },
                     filterReason: filterReason,
                 }

                // console.log("overtime item", generalItem.eyebrowEyeRatio)

                 if(state.overtimeById[d.id]) {
                     state.overtimeById[d.id].push(generalItem)
                 }else{
                     state.overtimeById[d.id] = [generalItem]
                 }

                 let blink = detectBlink(state.overtimeById[d.id], d.id)
                 item[`blink_${d.id}`] = blink
                 state.overtimeById[d.id][state.overtimeById[d.id].length - 1].blink = blink
                 state.overtimeById[d.id][state.overtimeById[d.id].length - 1][`blink_${d.id}`] = blink

                 let blinksPM = calculateFrequencyPerMinute(state.overtimeById[d.id], `blink_${d.id}`, BLINK_FREQUENCY_DURATION_SECONDS, BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS)

               // console.log("blink", blink)
                // console.log("blinksPM", blinksPM)
                 item[`blinksPM_${d.id}`] = blinksPM
                 state.overtimeById[d.id][state.overtimeById[d.id].length - 1].blinksPM = blinksPM


             }
             updatedOvertime.push(item)

            state.overtime = updatedOvertime;

            attentionCalculator.update(item);
            const avgMetrics = attentionCalculator.getAverageMetrics();

            state.alertsStats.totalAttentionTime = avgMetrics.avgTotalAttentionTime
            state.alertsStats.minAttentionTime = avgMetrics.avgMinAttentionTime
            state.alertsStats.maxAttentionTime = avgMetrics.avgMaxAttentionTime
            state.alertsStats.avgAttentionTime = avgMetrics.avgAttentionTime


        },
        setOvertimeById: (state, action) => {
            state.overtimeById[action.payload.id] = action.payload.data
        },
        resetOvertime: (state)  => {
            state.overtimeHistory.push(_.cloneDeep(state.overtime))
            state.overtime = []
        },
        setWidgetResults: (state, action) => {
            state.widgetResultsArray = action.payload
        },
        setAlertsData: (state, action) => {
            state.alertsData = action.payload
        },

    },
})

function calculateEyebrowDirection(browsOuterUp, browsDown) {

    // Normalize the values to be within the range [-1, 1]
    let normalizedUp = browsOuterUp ;
    let normalizedDown = browsDown;

    // Calculate the direction
    let direction = normalizedUp - normalizedDown;

    // Scale the result to be within [-100, 100]
    return direction * 100;
}

// Action creators are generated for each case reducer function
export const {
    pushToOvertime,
    resetOvertime ,
    setWidgetResults,
    setAlertsData,
    setOvertimeById
} = emotionalDataSlice.actions

export default emotionalDataSlice.reducer
